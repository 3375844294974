html,
body {
	overflow-x: hidden;
}
html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
}
body {
	margin: 0;
	min-height: 100vh;
	text-decoration-skip: none;
}
iframe {
	border: 0;
}
main {
	display: block;
}
ul,
ol {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 32px;
}
li {
	font-size: 18px;
	margin-bottom: 16px;
	line-height: 1.2;
}
dl {
	margin-top: 0;
	margin-bottom: 0;
}
dd {
	margin-left: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: inherit;
}
blockquote {
	margin: 0;
	padding: 0;
}
p {
	margin-top: 0;
	margin-bottom: 0;
}
sup {
	position: relative;
	top: -0.5em;
	vertical-align: baseline;
	font-size: 75%;
	line-height: 0;
}
strong {
	font-weight: bold;
}
figure {
	margin: 0;
}
fieldset {
	margin: 0;
	padding: 0;
	border: none;
}
img {
	max-width: 100%;
	height: auto;
	border: 0;
	vertical-align: middle;
}
a {
	color: inherit;
	text-decoration: none;
	transition: all 0.5s;
}
a,
img {
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}
button {
	overflow: visible;
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	text-align: inherit;
	text-transform: inherit;
	letter-spacing: inherit;
	font: inherit;
	cursor: pointer;
	-webkit-font-smoothing: inherit;
}
button,
[type='text'],
[type='password'],
[type='submit'],
[type='search'],
[type='email'],
[type='tel'],
textarea {
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
::-moz-focus-inner {
	padding: 0;
	border: 0;
}
table {
	border-spacing: 0;
	border-collapse: collapse;
}
th {
	vertical-align: middle;
}
td {
	vertical-align: middle;
}
*,
*::before,
*::after {
	box-sizing: border-box;
	outline: 0;
}
input::-ms-clear,
input::-ms-reveal {
	display: none;
}
input[type='radio'],
input[type='checkbox'] {
	margin: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	background-color: transparent;
	border: 0;
	border-bottom: 0 solid #3d4047;
	-webkit-text-fill-color: #707585;
	transition: background-color 5000s ease-in-out 0s;
}
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
	color: $color-text !important;
	background-color: $input-background !important;
	-webkit-box-shadow: 0 0 0 1000px $input-background inset !important;
	-webkit-text-fill-color: #596a73 !important;
}

html,
body,
.mobile-nav {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar,
.mobile-nav::-webkit-scrollbar {
	display: none;
}
