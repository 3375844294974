.header {
    padding-top: 32px;
    &-layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: 24px;
    }
    & ul {
        padding-left: unset;
        & li {
            display: flex;
            margin-bottom: unset;
        }
    }
    &__row {
        &:last-child {
            justify-self: flex-end;
        }
    }
    &-info {
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        line-height: 1;
    }
    &-menu {
        display: flex;
        justify-content: center;
        gap: 32px;
        &__item {
            font-size: 14px;
            font-weight: 600;
            &.absolute {
                flex-direction: column;
                gap: 12px;
                padding: 12px;
                z-index: 2;
                background-color: #FFFFFF;
            }
            &.has-children {
                &:hover div {
                    display: flex;
                }
            }
        }
        &__link {
            &:hover {
                text-decoration: underline;
            }
            &.active {
                color: #768185;
            }
        }

    }
    &-contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
        font-size: 14px;
        font-weight: 600;
    }
    &-user__link {
        position: relative;
        & .mall-cart-count {
            top: auto;
            position: absolute;
            display: flex;
            bottom: 14px;
            right: -10px;
        }
    }
    &-user-area {
        display: flex;
        align-items: center;
        gap: 24px;
    }
}
.top-bar {
    background-color: #FBFBFB;
    border-bottom: 1px solid #D7DBDF;
    padding: 12px 0;
    font-size: 14px;
    & .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        align-items: center;
    }
}