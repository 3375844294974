.product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    width: 100%;
    &-gallery {
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 600px;
    }
    &-title {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 0;
    }
    &-info {
        display: flex;
        flex-direction: column;
        gap: 48px;
        &-description {
            & p {
                margin-bottom: 16px;
            }
        }
    }
    &-card {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    &-add-card {
        display: flex;
        gap: 16px;
        justify-content: flex-start;
        align-items: center;
        & .mall-form-control {
            margin-bottom: 0;
            width: auto;
        }
        & .mt-4, .mall-add-to-wishlist-button {
            margin-top: 0;
        }
        & button:focus {
            outline: none;
        }
        & .mall-wishlist-button__popup-inner {
            min-width: 200px;
        }
    }
    &-add-wishlist {
        display: flex;
        gap: 8px;
        align-items: center;
    }
    &-name {
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 0;
        font-weight: 300;
    }
    &-description {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &-img {
        aspect-ratio: 3/2;
        background-color: #FBFBFB;
        border-radius: 16px;
        overflow: hidden;
        //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
        border: 1px solid #D7DBDF;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 60px;
    }
}
.section.mall-products {
    padding-bottom: 0;
    margin-bottom: 0;
    padding-left: 0;
}
.number {
    position: relative;
    display: flex;
    align-items: center;
    width: 120px;
    & .plus, .minus {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }
    & .plus {
        right: 0;
    }
    & .mall-input {
        margin-bottom: 0;
        text-align: center;
        padding: 0 50px;
    }
}
.mall-wishlist-button__popup-inner {
    .mall-wishlists.mall-wishlists--height-limited {
        flex-direction: column;
    }
}

.product-gallery {
    .mall-product__images {
        & .f-carousel__slide {
            aspect-ratio: 3 / 2;
            border: 1px solid #D7DBDF;
            overflow: hidden;
            border-radius: 16px;
        }

    }
    & .f-thumbs__slide {
        border: 1px solid #D7DBDF;
        overflow: hidden;
        border-radius: 8px;
    }
}
.product-search {
    &__results {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    &__item {
        display: flex;
        gap: 24px;
        border-bottom: 1px solid #D7DBDF;
        padding-bottom: 24px;
    }
    &__text {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
    }
    &__title {
        font-size: 24px;
        font-weight: 600;
    }
}
.mall-product__current-price {
    margin-bottom: 24px;
}
.mall-product__add-to-cart {
    gap: 24px;
    display: flex;
    flex-direction: column;
    & .mall-product__property-stock {
        margin-top: 0;
        margin-bottom: 0;
    }
}