//forms----------------------------------------------------------------
.form {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    //inputs----------------------------------------------------------------
    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='tel'],
    input[type='search'],
    input[type='file'],
    input[type='number'],
    textarea {
        width: 100%;
        height: 64px;
        padding: 20px 0;
        font-size: $font-size-default;
        line-height: 1.5;
        color: $color-text;
        background: transparent;
        border: 0;
        border-radius: 0;
        box-sizing: border-box;
        transition: all 0.4s;
        text-overflow: ellipsis;
        &::placeholder {
            transition: all 0.5s;
            text-overflow: ellipsis;
        }
        &:focus::placeholder {
            opacity: 0;
        }
        &:focus {
            border: 0;
        }
    }
    textarea {
        width: 100%;
        max-width: 100%;
        height: 64px;
        padding: 20px 20px 10px 0;
        border-radius: 0;
        line-height: 1.5;
        overflow: auto;
        box-sizing: border-box;
        resize: none;
        font-family: $font-regular;
    }
    .form-input {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: 24px;
        width: 100%;
        padding-left: 48px;
        border-bottom: solid 1px rgb(56 56 56 / 40%);
        &.error {
            & input {
                border-color: #DF2D14;
            }
        }
        &--file {
            input[type='file'] {
                position: absolute;
                opacity: 0;
            }
            input[type='text'] {
                pointer-events: none;
            }
        }
    }
    .form-submit {
        margin-top: 32px;
        text-align: right;
    }
    .form-label {
        font-weight: 700;
        font-size: 18px;
        width: 80px;
    }
    .form__input input[type='text']:disabled,
    .form__input input[type='password']:disabled,
    .form__input input[type='email']:disabled,
    .form__input input[type='tel']:disabled,
    .form__input textarea:disabled {
        color: $color-disabled;
        border-color: $color-disabled;
        cursor: default;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
}


