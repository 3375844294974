.search {
    position: relative;
    &:hover {
        cursor: pointer;
    }
}
.search-box {
    position: absolute;
    right: calc(100% - 30px);
    top: 80px;
    height: 60px;
    width: 300px;
    background: #3E8DA8;
    border-radius: 6px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s ease;
    z-index: 2;
    &:before{
        content: '';
        position: absolute;
        height: 20px;
        width: 20px;
        background: #477665;
        right: 10px;
        top: -6px;
        transform: rotate(45deg);
    }
    &.showInput {
        top: 35px;
        opacity: 1;
        pointer-events: auto;
        background: #477665;
    }
    & input{
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 4px;
        transform: translate(-50%, -50%);
        height: 50px;
        width: 290px;
        outline: none;
        padding: 0 15px;
        font-size: 16px;
        border: none;
    }
}

