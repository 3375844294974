.main-description {
    & h1 {
        margin-bottom: 0;
        line-height: 1.2;
    }
    & p {
        margin-bottom: 0;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}
.section {
    padding: 60px 0;
    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #D7DBDF;
        padding-bottom: 8px;
        margin-bottom: 50px;
        &--center {
            justify-content: center;
        }
    }
    &__title {
        margin-bottom: 0;
        font-size: 32px;
        line-height: 1.5;
        font-weight: 600;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }
    &--main {
        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 30px;
        & .section-row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 60px;
        }
    }
    &--about {
        display: grid;
        grid-template-columns: 1fr 1fr;
        & .section__header {
            margin-bottom: 40px;
        }
        & .section__title {
            padding-left: 80px;
            font-size: 18px;
            color: rgba(28, 29, 32, 0.4);
        }
        & .about {
            display: flex;
            flex-direction: column;
            gap: 40px;
            align-items: flex-start;
            padding-left: 80px;
            &-title {
                font-size: 42px;
                margin-bottom: 0;
                line-height: 1;
            }
            & p {
                color: #6F7273;
                margin-bottom: 0;
            }
            &__images {
                display: flex;
                gap: 20px;
                height: 100%;
            }
            &__img {
                &:first-child {
                    margin-top: auto;
                }
            }
        }
    }
}

.main-description {
    max-width: 500px;
}