.mall-my-account .mall-my-account__content {
    width: 100%;
    border: 1px solid #D7DBDF;
    border-radius: 0 0 16px 16px;
    z-index: 0;
    padding: 2rem;
}
.mall-my-account__nav-item {
    padding: 1rem;
    color: #222b2f;
    cursor: pointer;
    background-color: #fafcfc;
    border: 1px solid #D7DBDF;
    border-bottom-color: #fafcfc;
    border-radius: 16px 16px 0 0;
    margin-bottom: -1px;
    z-index: 1;
}