.feedback {
    &-form {
        position: relative;
        padding: 48px;
        z-index: 1;
        display: grid;
        grid-template-columns: 310px 1fr;
        grid-gap: 60px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: -100%;
            z-index: -1;
            background-color: rgb(219 255 0 / 67%);
        }
        &__header {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            padding: 0 48px;
            & span {
                font-size: 20px;
            }
        }
        &-contacts {
            display: flex;
            flex-direction: column;
            gap: 8px;
            font-size: 24px;
        }
        &__left {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }
        &__right {
            border-left: solid 1px rgb(56 56 56 / 40%);
        }
        &__notice {
            & p {
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 2px;
                padding-left: 48px;
                margin-top: 12px;
            }
        }
        &__links {
            text-align: right;
            margin-top: 12px;
            & p {
                font-size: 12px;
            }
            & a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}