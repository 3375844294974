.page {
    & .mall-wishlists-manager {
        flex-direction: column;
    }
    & .mall-wishlists-manager__lists {
        width: 100%;
    }
    & .mall-wishlists {
        display: flex;
        z-index: 1;
        margin-bottom: -1px;
    }
    & .mall-wishlists-manager__well {
        background-color: unset;
        box-shadow: unset;
    }
    .mall-wishlist-item {
        border: 1px solid transparent;
    }
    .mall-wishlist-item--active, .mall-wishlist-item:hover {
        background-color: #fafcfc;
        border: 1px solid #D7DBDF;
        border-bottom-color: #fafcfc;
        border-radius: 16px 16px 0 0;
        margin-bottom: -1px;
        z-index: 1;
    }
    .mall-wishlists-manager__contents {
        border: 1px solid #D7DBDF;
        border-radius: 0 0 16px 16px;
        z-index: 0;
    }
}