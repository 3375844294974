.breadcrumb {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 60px;
    & a {
        font-weight: 500;
        &:hover {
            text-decoration: underline;
        }
    }
    & span {
        color: #6F7273;
    }
}