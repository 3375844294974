.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    line-height: 40px;
    width: auto;
    font-size: 18px;
    box-sizing: border-box;
    text-align: center;
    user-select: none;
    padding: 0 20px;
    color: $color-text;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 8px;
    i {
        font-size: 14px;
        margin-left: 10px;
        text-shadow: none;
    }
    &:hover {
        transition: all 0.2s;
    }

    &--s1 {
        width: auto;
    }
    &--s2 {
        width: 80px;
        padding: 0 26px;

    }
    &--t1 {
        color: #FFFFFF;
        background: #477665;

    }
    &--t2 {
        color: #FFFFFF;
        background-color: #2B3134;
    }

}
