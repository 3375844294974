
@media all and (max-width: 1024px) {

}


@media all and (max-width: 980px) {
    .header-contacts {
        display: none;
    }

    .mobile-nav__opener {
        display: flex;
        font-size: 28px;
        justify-content: flex-end;
        margin-left: auto;
    }
    .header-menu {
        flex-direction: column;
    }
    .top-bar .container {
        grid-template-columns: auto 1fr;
        max-width: 100%;
    }
    .header, .main {
        & .container {
            padding: 0 24px;
            max-width: 1288px;
        }
    }
    .header {
        & .header-menu {
            display: none;
        }
    }
    .section--main {
        display: flex;
        flex-direction: column;
        & .section-row {
            align-items: center;
        }
    }
    .main-description {
        max-width: 100%;
    }
    .reviews-grid {
        display: flex;
        flex-direction: column-reverse;
        & .reviews-grid__img {
            text-align: center;
        }
    }
    .reviews-grid__img img {
        position: relative;
    }
    .categories-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
    }
    .section--about {
        display: flex;
        flex-direction: column-reverse;
        gap: 40px;
        & .about {
            padding-left: unset;
        }
        & .about__images {
            justify-content: center;
            flex-wrap: wrap;
        }
    }
    .product-list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
    }
    .product {
        display: flex;
        flex-direction: column-reverse;
        gap: 0;
    }
    .product-gallery {
        max-width: 100%;
        width: 100%;
    }

    .footer-layout {
        display: flex;
        flex-direction: column;
    }
}



@media all and (max-width: 640px) {
    .search-box.showInput {
        top: 160px;
    }
    .search-box {
        position: fixed;

        left: 0;
        right: 0;
        width: 100%;
        & input {
            width: calc(100% - 8px);
        }
    }
    .section {
        padding: 20px 0;
    }
    .main-description, .section--about .about {
        align-items: center;
    }
    .main-description h1, .section--about .about-title {
        font-size: 32px;
    }
    .reviews-grid .reviews-grid__img {
        & img {
            width: 100%;
        }
    }
    .categories-grid {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .about__img {
        width: 100%;
        & img {
            width: 100%;
        }
    }
    .header-user-area {
        font-size: 24px;
    }
    .signup {
        padding: 20px;
        & .mall-two-fields {
            flex-direction: column;
            &>:first-child {
                margin-right: 0;
                margin-bottom: 1.5rem;
            }
            &>:last-child {
                margin-left: 0;
            }
        }
    }
    .signup__tab {
        padding: 0 20px 20px;
    }
    .signup__signin form {
        max-width: 100%;
    }
    .product-list {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
    }
    .product-search__item {
        flex-direction: column;
    }
    .page {
        .mall-cart__empty {
            width: 100%;
            margin: unset;
        }
        .mall-quick-checkout {
            flex-direction: column;
            gap: 24px;
            & .mall-quick-checkout__content-column {
                padding-right: 0;
            }
            & .mall-quick-checkout__cart-column {
                padding-left: 0;
            }
            & .mall-cart-summary__info {
                flex-direction: column;
                gap: 32px;
                margin-bottom: 0;
                &>:not(:last-child) {
                    padding-right: 0;
                }
            }
            & .mall-cart-summary__info--two-columns>* {
                width: 100%;
            }
            .mall-cart-summary .mall-address__actions {
                font-weight: 600;
            }

        }
        & .mall-checkout-result {
            width: 100%;
        }
        & .mall-wishlists {
            flex-direction: column;
        }

       & .mall-wishlists-manager__actions {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        & .mall-discount-applier form {
            width: 100%;
        }
    }
    .mall-my-account {
        .mall-two-fields {
            display: flex;
            flex-direction: column;
            gap: 24px;
            &>:last-child {
                margin-left: unset;
            }
        }
        .mall-address-list {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        & .mall-address-list__actions {
            display: flex;
            float: unset;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
        & table.mall-table.mall-address-list__table tr {
            display: flex;
            flex-direction: column;
        }
        & .mall-switcher, .mall-switcher__item {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
       & .mall-address-list__table-actions a {
            margin-right: 0;
            margin-top: 24px;
            display: block;
        }
    }

    .footer-layout {
        gap: 20px;
    }
    .footer-row {
        gap: 20px;
    }
    .footer-copyright {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    }
}



