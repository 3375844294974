.mall-order-details__detail p {
    font-size: 14px;
}
.mall-my-account {
    flex-direction: column;
    .mall-my-account__nav {
        width: 100%;
        display: flex;
    }
    & .mall-my-account__content {
        width: 100%;
        padding-left: 0;
    }
}