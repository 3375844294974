.signup {
    background-color: #FBFBFB;
    border-radius: 16px;
    padding: 80px;
    &__signin, &__signup {
        display: none;
        margin-top: 60px;
    }
    &__signin {
        & form {
            max-width: 300px;
            margin: auto;
        }
    }
    &__signup {
        & form {
            max-width: 600px;
            margin: auto;
        }
    }
    &__buttons {
        display: flex;
        align-items: center;
    }
    &__tab {
        cursor: pointer;
        width: 50%;
        text-align: center;
        padding: 0 20px 40px;
        color: #1C1D2066;
        border-bottom: 1px solid #D7DBDF;
        &:first-child {
            border-right: 1px solid #D7DBDF;
        }
        &.active {
            color: #0D0E10;
            border-bottom: 3px solid #0D0E10;
        }
    }
}

.main .mall-form-control input, .mall-form-control select, .mall-form-control textarea {
    border-color: #dae4e9;
    background-color: #FFFFFF;
}
