body {
	color: $color-text;
	transition: color 0.4s;
	background-color: $color-body;
	z-index: 0;
	overflow-x: hidden;
}

.main {
	position: relative;
	z-index: 1;
}

// -----------------------------------------------
// Container
// -----------------------------------------------
.container {
	position: relative;
	margin: 0 auto;
	padding: 0 24px;
	max-width: 1288px;
	width: 100%;
	&--fixed {
		position: fixed;
		z-index: 11;
		bottom: 24px;
		left: 0;
		right: 0;
		max-width: 1440px;
	}
}



// -----------------------------------------------
// Sections styles
// -----------------------------------------------

