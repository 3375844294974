.categories-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 80px;
    & .category {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 8px;
        &__title {
            position: absolute;
            display: flex;
            left: 0;
            margin-bottom: 0;
            font-size: 18px;
            transform: rotate(-90deg);
            transform-origin: top left;
            line-height: 1;
            bottom: -20px;
            top: auto;
            text-transform: uppercase;
            font-weight: 400;
        }
        &__img {
            border-radius: 16px;
            overflow: hidden;
            padding-left: 30px;
        }
    }
}
.slider-categories {
    margin-top: 48px;
}
.category {

    &-name {
        font-size: 18px;
        text-transform: uppercase;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 0;

    }
}