.review {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 30px;
    border-radius: 16px;
    background-color: #FBFBFB;
    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &__description {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
    }
    &__author {
        font-size: 14px;
        margin-bottom: 0;
        color: #6F7273;
    }
}
.reviews__nav {
    display: flex;
    gap: 20px;
    font-size: 32px;
    margin-top: 20px;
}
.reviews-grid {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-gap: 30px;
    width: 100%;
    &__img {
        position: relative;
        & img {
            position: absolute;
            bottom: 0;
        }
    }
}