.project {
    display: flex;
    flex-direction: column;
    gap: 100px;
    &--01 {
        background-image: url('../../img/backgrounds/project01-bg.svg'), linear-gradient(#061926 0%, #165C8C 100%);
        background-position: top right;
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    &--02 {
        background-image: url('../../img/backgrounds/project02-bg.png'), linear-gradient(119deg, #5019AC -0.02%, #361966 61.28%, #4907AE 87.35%, #4F02C2 110.42%);
        background-position: top right;
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    &--03 {
        background-image: url('../../img/backgrounds/project03-bg.svg'), linear-gradient(117deg, #003177 3.84%, #00419D 139.53%);
        background-position: top right;
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    &--04 {
        background-image: linear-gradient(180deg, #F2F4FF 0%, #F2F2F5 20.5%, #F4EBCA 24.5%);
        & .project-tag {
            border-color: #000000;
        }
    }
    &--05 {
        background-image: url('../../img/backgrounds/project05-bg.svg'), linear-gradient(0deg, #172E5C 0%, #172E5C 100%);
        background-position: top right;
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    &-intro {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }
    &-header {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    &-subtitle {
        display: flex;
        align-items: center;
        gap: 4px;
        text-transform: uppercase;
        line-height: 1;
    }
    &-title {
        font-size: 48px;
        margin-bottom: 0;
        text-transform: uppercase;
    }
    &-description {
        padding-left: 180px;
        & p {
            font-size: 28px;
        }
    }
    &-tags {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    &-tag {
        border: 2px solid #FFFFFF;
        border-radius: 50px;
        padding: 6px 12px;
    }
    &-params {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        &__digit {
            font-size: 80px;
        }
        &__desc {
            text-transform: uppercase;
        }
        &__list {
            display: flex;
            gap: 56px;
        }
    }
    &-feature {
        display: flex;
        flex-direction: column;
        gap: 40px;
        &-img {
            &__grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 24px;
                &--four {
                    grid-template-columns: repeat(4, 1fr);
                }
                &--three {
                    grid-template-columns: repeat(2, 1fr);
                    & .project-feature-img:last-child {
                        grid-area: 2/1/2/3;
                    }
                }
                &--three-line {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }
}