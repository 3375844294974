.slider-products {
    &__nav {
        display: flex;
        justify-content: center;
        gap: 40px;
        font-size: 32px;
        margin-top: 40px;
    }
}
.swiper-button-prev, .swiper-button-next {
    &:hover {
        cursor: pointer;
    }
}