.mobile-nav {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: space-between;
    &__wrapper {
        position: fixed;
        top: 0;
        right: -80%;
        width: 80%;
        height: 100%;
        background-color: #FBFBFB;
        color: white;
        transition: right 0.3s ease;
        z-index: 20;
        padding: 24px;
        & .header-contacts {
            margin-top: 60px;
        }
        & .header-contacts, .header-nav, .footer-links {
            display: flex;
            align-items: flex-start;

        }
        & .socials__link {
            display: flex;
            color: #FFFFFF;
        }
    }
    &__footer {
        margin-bottom: 60px;
        & .copyright {
            opacity: 1;
            visibility: visible;
            color: #FFFFFF;
            gap: 20px;
        }
        & .socials {
            display: flex;
            gap: 12px;
            font-size: 24px;
            align-items: flex-end;
            justify-content: flex-end;
        }
        & .copyright-links {
            gap: 4px;
            flex-direction: column;
            align-items: flex-end;
        }
        & .copyright-text {
            text-align: right;
        }
    }

}

.mobile-nav__opener {
    display: none;
    width: 20px;
    height: 20px;
}
.burger-menu__closer {
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    font-size: 20px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__item {
        display: flex;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    &-link {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        & i {
            transform: rotate(90deg);
        }
    }

}