.cookies {
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        padding: 16px 16px 16px 48px ;
        width: 380px;
        background: #FFFFFF;
    }
    &-close {
        display: block;
        text-transform: uppercase;
        text-align: right;
        line-height: 1;
    }
    &__message {
        width: 100%;
        & a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}