.pager {
    margin-top: 32px;
    & .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 8px;
    }
    & .page-item {
        display: flex;
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 0;
        & a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
        &.active {
            color: #FFFFFF;
            background-image: url('../img/elements/triangle.svg');
            background-position: center;
            background-repeat: no-repeat;

        }
        &--prev, &--next, &--divide {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin-bottom: 0;
        }
    }
    &.mall-pagination a, &.mall-pagination span {
        min-width: 40px;
        min-height: 40px;
    }
    &.mall-pagination .active>a, &.mall-pagination .active>span {
        color: unset;
        background-color: unset;
        border-color: unset;
    }
}


