.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 60px 0;
    background-color: #FBFBFB;
    & ul {
        padding-left: unset;
        & li {
            display: flex;
            margin-bottom: unset;
        }
    }
    &-row {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    &-layout {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 40px;
    }
    &-about {
        & p {
            font-size: 16px;
            font-weight: 600;
        }
    }
    &-menu {
        &__title {
            font-size: 14px;
            font-weight: 600;
        }
        &__list {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        &__link {
            &:hover {
                text-decoration: underline;
            }
        }
        &__item {
            color: #41454E;
            font-size: 13px;
            font-weight: 500;
        }
    }
    &-copyright {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-items: center;
        font-size: 14px;
        margin-top: 60px;
        &__row {
            &:first-child {
                justify-self: flex-start;
            }
            &:last-child {
                justify-self: flex-end;
            }
        }
        &__text {
            color: #1C1D20;
            opacity: .5;
        }
        &__terms {
            display: block;
            margin-left: auto;
        }
    }

}

.copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    text-align: right;
}

