.page {
    padding-top: 32px;
    padding-bottom: 48px;
    &-header {
        border-top: 1px solid #D7DBDF;
        padding-top: 60px;
    }
    &-title {
        margin-bottom: 0;
    }
    &-desc {
        display: flex;
        gap: 120px;
        &-header {
            flex-shrink: 0;
        }
    }
    &-info {
        h2 {
            font-size: 24px;
        }
        p:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}